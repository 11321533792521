import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";
import axios from "axios";

export async function fetchProjectStructureQuery(
  userId,
  projectUuid,
  type,
  newProject = false,
  fromTemplate
) {
  const res = await fetchData("/api/session/join", APIMethod.GET, {
    noAuth: true,
    baseURL: ACCESS_DOMAINS.editor,
    mapRequestKeysToSnakeCase: false,
    params: {
      userId,
      uuid: projectUuid,
      token: axios.defaults.headers.common["Authorization"],
      type,
      newProject,
      fromTemplate,
      timestamp: new Date().getTime()
    },
  });

  return res.results;
}
