export const EditorCommands = {
  CONNECT: "connect",
  RECONNECT: "reconnect",
  CONNECT_ERROR: "connect_error",
  DISCONNECT: "disconnect",
  MOVE: "move",
  MOVING: "userMoving",
  JOIN: "join",
  USER_JOINED: "userJoined",
  USER_LEFT: "userLeft",
  CHANGE: "change",
  PATCH: "patch",
  CHANGE_MADE: "userChange",
  CHANGE_ERROR: "changeError",
  PATCHES_MADE: "userPatches",
  VERSION_NEW: "newVersion",
  VERSION_REMOVE: "removeVersion",
  VERSION_RENAME: "renameVersion",
  VERSION_ORDER: "orderVersion",
  VERSION_ORDER_UPDATE: "orderVersionUpdate",
  PROJECT_RENAME: "renameProject",
  NEW_WORKTYPE: "newWorkType",
  DUPLICATE_SESSION: "duplicateSession",
  PERMIT_ADD: "addPermit",
  PERMIT_REMOVE: "removePermit",
  PERMIT_STATUS: "updatePermit",
  PERMIT_USER_VISIBILITY: "updateUserWorkTypeVisibility",
  REQUEST_SAVE: "requestSave",
  COVER_ADD: "newCover",
  COVER_REMOVE: "removeCover",
  COVER_PAGES: "updateCoverPages",
  COVER_SCALE: "updateCoverScale",
  
  COMMENT_ADD: "commentAdd",
  COMMENT_EDIT: "commentEdit",
  COMMENT_REMOVE: "commentRemove",
  COMMENT_REMOVE_PATH: "commentRemovePath",
  COMMENT_MOVE: "commentMove",
  
  USER_ADD: "user_add",
  USER_REMOVE: "user_remove",
  USER_PROMOTE: "user_promote",
  
  NOTIFICATION: "notification",
  ALERT: "alert",
  
  SHARE_SETTINGS: "shareSettings",
};
