import { useRef } from "react";
import { bool, number, object, oneOf, string } from "prop-types";
import { observer } from "mobx-react";
import { useBreakdownValueAlert, useStructureStore } from "@hooks";
import { DifferentValueAlert } from "@dialogs";
import { PriceCell } from "@components";
import classnames from "classnames";

export const TaskPrice = observer(({
  element,
  editable,
  actualDepthLevel,
  isLibrary,
  isSectionRow,
  numOfVisibleWorkTypes,
  opened,
  tableDepth,
  isLastCell,
  className,
}) => {
  
  const structure = useStructureStore();
  
  const changeValueData = useRef(null);
  
  const { estimateValueLevel, settings, usesAvgValue, usesTwoValues, usesAnyValue } = structure;
  
  const {
    currencyObj,
    roundPrice,
    showBreakdownParentValues,
  } = settings;
  
  const {
    displayPrice,
    displayPriceMax,
    hasChildren,
    parentHoldsPrice,
  } = element;
  
  const {
    valueAlertVisible,
    handleValueAlertClose: onValueAlertClose,
    handleValueAlertOpen,
  } = useBreakdownValueAlert();
  
  const holdsSomeValues = Boolean(Object.entries(element.holdsViewedHours).length);
  
  const displayCurrentCellContent = !opened || !hasChildren || tableDepth + 1 > estimateValueLevel;
  
  const displayCellContent = actualDepthLevel <= estimateValueLevel && (showBreakdownParentValues || displayCurrentCellContent);
  
  const blurDisplayedValue = displayCellContent && !displayCurrentCellContent;
  
  const showMaxValue = usesTwoValues && (editable || displayPrice !== displayPriceMax);
  
  const parentHoldsAllValues = parentHoldsPrice && !editable && usesAnyValue
    || Boolean(numOfVisibleWorkTypes && Object.entries(element.parentHoldsHours).length === numOfVisibleWorkTypes);
  
  
  const handlePriceChange = (price, isMax) => {
    const key = isMax ? "max" : usesAvgValue ? "avg" : "min";
    
    if(!element.hasCustomPrice && (element.hasFixedPrice || element.hasChildren)) {
      changeValueData.current = { key, price };
      handleValueAlertOpen();
      return true;
    }
    else
      element.setCustomPrice({ [key]: price }, hasChildren, hasChildren, true);
  };
  
  const handleValueAlertClose = (clearChildren) => {
    onValueAlertClose();
    if(typeof clearChildren === "undefined") return;
    if(changeValueData.current) {
      const {key, price} = changeValueData.current;
      element.setCustomPrice({ [key]: price }, clearChildren, clearChildren, true);
    }
  }

  return (
    <PriceCell
      wider={usesTwoValues}
      value={displayPrice}
      valueMax={displayPriceMax}
      emptyInputs={parentHoldsPrice}
      editable={editable && !isLibrary}
      onValueChange={handlePriceChange}
      displayMax={showMaxValue}
      noContainerBackground={isSectionRow}
      displayContent={element.hasCustomPrice || (((displayCellContent && !parentHoldsAllValues) || holdsSomeValues) && actualDepthLevel <= estimateValueLevel)}
      showDecimals={!roundPrice}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      usePresetClass={!isSectionRow}
      useSectionClass={isSectionRow}
      extendPadding={isLastCell}
      allowNegative
      className={className}
      inputClassName={classnames(
        "input-price",
        blurDisplayedValue && "transparent-2",
      )}
    >
      {editable && valueAlertVisible && (
        <DifferentValueAlert
          moduleLevel={actualDepthLevel}
          handleValueAlertClose={handleValueAlertClose}
        />
      )}
    </PriceCell>
  )
});

TaskPrice.propTypes = {
  element: object.isRequired,
  numOfVisibleWorkTypes: number.isRequired,
  actualDepthLevel: oneOf([0, 1, 2, 3]),
  allowEdition: bool,
  isLibrary: bool,
  displayCellContent: bool,
  isSectionRow: bool,
  opened: bool,
  isLastCell: bool,
  tableDepth: number,
  className: string,
};