import { createContext, useEffect, useRef, useState } from "react";
import { bool, node, string } from "prop-types";
import { useStores, useProjectEditorStore, useEditorCommentStore } from "@hooks";
import { useSnackbar } from "notistack";
import { Manager } from "socket.io-client";
import socketParser from "socket.io-msgpack-parser";
import { applyAction, applyPatch } from "mobx-state-tree";
import { hashCode, REMOVE_ACTIONS } from "project-structure";
import { EditorCommands } from "@client";
import { EDITOR_CONTAINER_ID, PROPOSAL_STEPPER_ID, SNACKBAR_WEBSOCKET_RESTORED } from "@utils";
import { useTranslation } from "react-i18next";
import { fetchProjectStructureQuery, fetchProposalCommentsQuery } from "@query";
import { MAX_PAGE_WIDTH } from "@styles/themes";

export const EditorSocketContext = createContext();

export const EditorSocketProvider = ({ projectUuid, commentHash, children }) => {

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { userStore } = useStores();
  const editorStore = useProjectEditorStore();
  const commentStore = useEditorCommentStore();

  // const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const [connectionOnline, setConnectionOnline] = useState(false);
  const [hasConnectionError, setConnectionError] = useState(false);

  const socket = useRef(null);
  const manager = useRef(null);
  const editionPrivileges = useRef({
    joinEdition: false,
    joinComments: false
  });
  const lastSentAction = useRef(null);
  const connectionOnlineSnackbar = useRef(null);
  const connectionRestoredSnackbar = useRef(null);

  useEffect(() => () => {
    lastSentAction.current = "END";
    closeConnection();
    closeSnackbar(connectionOnlineSnackbar.current);
    closeSnackbar(connectionRestoredSnackbar.current);
  }, []);

  useEffect(() => {
    socket.current?.on(EditorCommands.USER_JOINED, onJoined);
    socket.current?.on(EditorCommands.USER_LEFT, onLeft);

    return () => {
      socket.current?.off(EditorCommands.USER_JOINED, onJoined);
      socket.current?.off(EditorCommands.USER_LEFT, onLeft);
    };
  }, [socket, users])

  useEffect(() => {
    socket.current?.on(EditorCommands.CONNECT_ERROR, onDisconnect);
    socket.current?.on(EditorCommands.DISCONNECT, onDisconnect);

    return () => {
      socket.current?.off(EditorCommands.CONNECT_ERROR, onDisconnect);
      socket.current?.off(EditorCommands.DISCONNECT, onDisconnect);
      
    };
  }, [socket, connectionOnline])

  //
  const init = (openEditionChannel, openCommentsChannel=false) => {
    editionPrivileges.current = {
      joinEdition: openEditionChannel,
      joinComments: openCommentsChannel && commentHash
    }
    manager.current = new Manager(sessionStorage.getItem("ws_green")
      ? process.env.REACT_APP_COLLAB_SERVICE_GREEN
      : process.env.REACT_APP_COLLAB_SERVICE,
      {
        path: process.env.REACT_APP_COLLAB_SERVICE_WS_PATH,
        host: sessionStorage.getItem("ws_green")
          ? process.env.REACT_APP_COLLAB_SERVICE_GREEN_WS_HOST
          : process.env.REACT_APP_COLLAB_SERVICE_WS_HOST,
        transports: ["websocket"],
        parser: socketParser,
      });
    socket.current = manager.current.socket("/");
    socket.current.on(EditorCommands.CONNECT, onConnect);
    manager.current.on(EditorCommands.RECONNECT, onReconnect);

    if(openEditionChannel) {
      socket.current.on(EditorCommands.MOVING, onMoving);
      socket.current.on(EditorCommands.CHANGE_MADE, onProjectChange);
      socket.current.on(EditorCommands.CHANGE_ERROR, onProjectChangeError);
      socket.current.on(EditorCommands.PATCHES_MADE, onProjectUndo);
      socket.current.on(EditorCommands.VERSION_NEW, onCreateVersion);
      socket.current.on(EditorCommands.VERSION_REMOVE, onRemoveVersion);
      socket.current.on(EditorCommands.VERSION_RENAME, onRenameVersion);
      socket.current.on(EditorCommands.VERSION_ORDER, onReorderVersion);
      socket.current.on(EditorCommands.VERSION_ORDER_UPDATE, onVersionOrderUpdate);
      socket.current.on(EditorCommands.PROJECT_RENAME, onRenameProject);
      socket.current.on(EditorCommands.NEW_WORKTYPE, onAddNewWorkType);
      socket.current.on(EditorCommands.PERMIT_ADD, onWorkTypeAssignment);
      socket.current.on(EditorCommands.PERMIT_REMOVE, onWorkTypeRevoke);
      socket.current.on(EditorCommands.PERMIT_STATUS, onWorkTypeStatus);
      socket.current.on(EditorCommands.PERMIT_USER_VISIBILITY, onUserWorkTypesStatus);
      socket.current.on(EditorCommands.COVER_ADD, onCoverAdd);
      socket.current.on(EditorCommands.COVER_REMOVE, onCoverRemove);
      socket.current.on(EditorCommands.COVER_PAGES, onCoverUsedPagesUpdate);
      socket.current.on(EditorCommands.COVER_SCALE, onCoverScaleUpdate);
      socket.current.on(EditorCommands.SHARE_SETTINGS, onShareSettingsUpdate);
    }
    
    if(openCommentsChannel) {
      socket.current.on(EditorCommands.COMMENT_ADD, onCommentAddOrEdit);
      socket.current.on(EditorCommands.COMMENT_EDIT, onCommentAddOrEdit);
      socket.current.on(EditorCommands.COMMENT_REMOVE, onCommentRemove);
      socket.current.on(EditorCommands.COMMENT_REMOVE_PATH, onCommentPathRemove);
      socket.current.on(EditorCommands.COMMENT_MOVE, onCommentMove);
    }
  };

  // --------- connections ---------

  //
  const closeConnection = () => {
    setConnectionOnline(false);
    socket.current?.close();
    setUsers([]);
  };

  //
  const onConnect = () => {
    console.log("CONNECTED");
    setConnectionOnline(true);
    socket.current.emit(EditorCommands.JOIN, {
      projectUuid: projectUuid,
      commentHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
      avatar: userStore.data.avatar,
      ...editionPrivileges.current,
    });
  };

  const resetProjectData = async () => {
    const projectData = await fetchProjectStructureQuery(
      userStore.userId,
      projectUuid
    );
    editorStore.setProjectData(projectData, false, false, true);
  }
  
  const refetchProjectComments = async () => {
    if(!commentStore) return;
    const c = await fetchProposalCommentsQuery(projectUuid, editorStore.currentVersionKey);
    commentStore.setComments(c, editorStore.currentVersionKey);
  }

  const onReconnect = () => {
    console.log("RECONNECTED");
    if(editionPrivileges.current.joinEdition) {
      resetProjectData();
      closeSnackbar(connectionOnlineSnackbar.current);
      connectionRestoredSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_restored"),
        {
          key: SNACKBAR_WEBSOCKET_RESTORED,
          variant: "success",
          autoHideDuration: 2000
        }
      );
    }
    refetchProjectComments();
    setConnectionOnline(true);
  };

  const onDisconnect = (reason) => {
    console.log("DISCONNECTED", reason);
    setConnectionOnline(false);
    if(connectionOnline && lastSentAction.current !== "END" && editionPrivileges.current.joinEdition) {
      closeSnackbar(connectionRestoredSnackbar.current);
      connectionOnlineSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_offline"),
        {
          persist: true,
          variant: "warning"
        }
      );
    }
    setUsers([]);
  };

  const madeIdenticalAction = (command, payload, additionalChecks) => {
    if (!lastSentAction.current) return false;
    return (
      lastSentAction.current.command === command &&
      lastSentAction.current.timestamp > payload.timestamp &&
      (!additionalChecks || additionalChecks(lastSentAction.current.payload))
    );
  };

  const saveAndSendAction = (command, payload) => {
    if (!socket.current?.connected) return;
    const timestamp = new Date().getTime();
    payload.timestamp = timestamp;
    lastSentAction.current = { command, payload, timestamp };
    sendAction(command, payload);
  };

  const sendAction = (command, payload) => {
    if (!socket.current?.connected) return;
    socket.current.emit(command, payload);
  };

  // --------- remote actions ---------

  const onJoined = (userData) => {
    setUsers([...users, ...userData])
  };

  const onLeft = (userIds) => {
    setUsers(users.filter((u) => !userIds.includes(u.socketId)));
  };

  const onMoving = (data) => {
    if(!data?.id)
      return;
    
    const cursor = document.getElementById(hashCode(data.id));

    if (cursor) {
      let target =
        data.elementId &&
        document.querySelector(
          `[data-id="${data.elementId}"][data-t="${data.elementType}"]`
        );
      let parent,
        i = 0;

      if (data.elementPath && !target) {
        const l = data.elementPath.split("/").reverse();
        do {
          parent = document.querySelector(`[data-id="${l[i]}"]`);
          i++;
        } while (!parent && i < l.length);
      }

      if (target || !parent) {
        // const innerHeight = document.getElementById(TABLE_CONTAINER_ID).clientHeight;

        const scaleX =
          Math.min(MAX_PAGE_WIDTH, window.innerWidth) / Math.min(MAX_PAGE_WIDTH, data.vW);
        // const scaleY = innerHeight / data.vH;
        const offsetLeft =
          window.innerWidth < MAX_PAGE_WIDTH ? 24 : (window.innerWidth - MAX_PAGE_WIDTH) / 2;
        const x = Math.round(data.x * scaleX) + offsetLeft;
        const y = Math.round(data.y);

        cursor.style.top = `${y}px`;
        cursor.style.left = `${x}px`;
      } else if (parent) {
        const con = document.getElementById(EDITOR_CONTAINER_ID);
        if(!con) return;
        const stepperHeight =
          document.getElementById(PROPOSAL_STEPPER_ID)?.getBoundingClientRect()
            .height || 0;
        const par = parent.getBoundingClientRect();
        const l = parent.querySelector(".name")?.getBoundingClientRect()?.left;
        cursor.style.top = `${
          par.top +
          par.height / 2 +
          con.scrollTop -
          stepperHeight -
          con.getBoundingClientRect()?.y
        }px`;
        cursor.style.left = `${(l || par.left) + 24}px`;
      }
    }
  };

  const onProjectChangeError = (data) => {
    console.error("ERROR => Project not found", data);
    setConnectionError(true);
  }
  
  const onProjectChange = (data) => {
    if (
      madeIdenticalAction(
        EditorCommands.CHANGE,
        data,
        (p) =>
          p.actionData.name === data.actionData.name &&
          p.actionData.path === data.actionData.path &&
          (![
            "changeValue",
            "setTimelineOverride",
            "resetTimelineOverride",
          ].includes(p.actionData.name) ||
            p.actionData.args[0] === data.actionData.args[1])
      )
    ) {
      // console.log(
      //   `IDENTICAL ACTION "${lastSentAction.current.command}" has already been made on client`
      // );
      return;
    }
    
    if (data.versionKey !== editorStore.currentVersion) {
      data.actionData.args = data.actionData.args.slice(1);
    }

    const structure = editorStore.projectVersions.find(
      (v) => v.key === data.versionKey
    )?.structure;
    if (structure) try {
      structure.historyManager.withoutUndo(() => {
        if(REMOVE_ACTIONS.includes(data.actionData.name))
          structure.historyManager.clearByTargetId(data.identifier)
        applyAction(structure, data.actionData);
      })
    } catch(e) {
      console.log("Error: applyAction", e);
    }
  };
  
  const onProjectUndo = (data) => {
    
    const structure = editorStore.projectVersions.find(
      (v) => v.key === data.versionKey
    )?.structure;
    if (structure) try {
      structure.historyManager.withoutUndo(() => {
        // console.log("patches!");
        data.patches.forEach(p => {
          if(p.removesElement && p.identifier)
          structure.historyManager.clearByTargetId(p.identifier)
        })
        applyPatch(structure, data.patches);
      })
    } catch(e) {
      console.log("Error: applyPatch", e);
    }
  };

  const onCreateVersion = ({ versionData, userId }) => {
    editorStore.addNewVersion(versionData, Number(userId) !== userStore.data.id);
  };

  const onRemoveVersion = ({ versionKey, versionOrder }) => {
    editorStore.removeSelectedVersion(versionKey, versionOrder);
  };
  
  const onVersionOrderUpdate = ({ versionOrder }) => {
    editorStore.updateVersionOrder(versionOrder);
  };

  const onRenameVersion = (data) => {
    if (
      madeIdenticalAction(
        EditorCommands.VERSION_RENAME,
        data,
        (p) => p.versionKey === data.versionKey
      )
    )
      return;
    const { versionKey, newName } = data;
    editorStore.editVersionName(versionKey, newName);
  };

  const onReorderVersion = (data) => {
    if (madeIdenticalAction(EditorCommands.VERSION_ORDER, data)) return;
    editorStore.reorderVersions(data.newOrder);
  };

  const onRenameProject = (data) => {
    if (madeIdenticalAction(EditorCommands.PROJECT_RENAME, data)) return;
    editorStore.setProjectName(data.newName);
  };

  const onAddNewWorkType = ({ id, name, backgroundColor }) => {
    editorStore.addCustomTag(id, name, backgroundColor);
  };

  const onWorkTypeAssignment = ({ workTypeId, userUuid }) => {
    editorStore.addLocalPermit(workTypeId, userUuid);
  };

  const onWorkTypeRevoke = ({ workTypeId, userUuid }) => {
    editorStore.removeLocalPermit(workTypeId, userUuid);
  };

  const onWorkTypeStatus = ({ workTypeId, status }) => {
    editorStore.updateLocalPermit(workTypeId, status);
  };

  const onUserWorkTypesStatus = ({ userId, status }) => {
    editorStore.setProjectUserWorkTypeVisibility(userId, status);
  };

  const onCoverAdd = (cover) => {
    editorStore.setPdfDocument(cover);
  };

  const onCoverRemove = () => {
    editorStore.removeDocument();
  };

  const onCoverUsedPagesUpdate = ({ docId, usedPages, tablePos }) => {
    editorStore.setUsedPages(usedPages, docId);
    if(tablePos)
      editorStore.setTablePos(tablePos, docId);
  };

  const onCoverScaleUpdate = ({ scale }) => {
    editorStore.setScale(scale.scale, scale.scaleValue, false);
  };
  
  const onCommentAddOrEdit = (payload) => {
    commentStore.addOrUpdateComment(payload);
  };
  
  const onCommentRemove = ({ commentId, parent }) => {
    commentStore.removeComment(commentId, parent);
  };
  
  const onCommentPathRemove = ({ version, place }) => {
    commentStore.removeCommentByPath(version, place);
  };
  
  const onCommentMove = ({ version, place, oldPlace }) => {
    commentStore.updateCommentPath(version, oldPlace, place);
  };
  
  const onShareSettingsUpdate = ({ settings }) => {
    editorStore.setAllShareLinkSettings(JSON.parse(settings));
  };

  // --------- user actions ---------

  const sendMoveData = (
    clientX,
    clientY,
    elementType,
    elementPath,
    elementId
  ) => {
    sendAction(EditorCommands.MOVE, {
      id: userStore.data.id,
      vW: window.innerWidth,
      // vH: containerHeight,
      x: clientX,
      y: clientY,
      elementType,
      elementPath,
      elementId,
    });
  };

  const requestStructureChange = (actionData) => {
    if(actionData.name === "applyVisibility") return;
    saveAndSendAction(EditorCommands.CHANGE, {
      versionKey: editorStore.currentVersionKey,
      actionData,
    });
  };
  
  const requestStructurePatch = (patches) => {
    saveAndSendAction(EditorCommands.PATCH, {
      versionKey: editorStore.currentVersionKey,
      patches,
    });
  };
  
  const requestStructureVersionChange = (actionData, versionKey) => {
    saveAndSendAction(EditorCommands.CHANGE, {
      versionKey,
      actionData,
    });
  };

  const requestNewVersion = () => {
    saveAndSendAction(EditorCommands.VERSION_NEW, {
      originVersionKey: editorStore.currentVersionKey,
    });
  };

  const requestVersionRename = (versionKey, newName) => {
    saveAndSendAction(EditorCommands.VERSION_RENAME, { versionKey, newName });
  };

  const requestVersionRemoval = (versionKey) => {
    saveAndSendAction(EditorCommands.VERSION_REMOVE, { versionKey });
  };

  const requestVersionReorder = (movedVersionKey, newOrder) => {
    saveAndSendAction(EditorCommands.VERSION_ORDER, {
      movedVersionKey,
      newOrder,
    });
  };

  const requestProjectRename = (newName) => {
    saveAndSendAction(EditorCommands.PROJECT_RENAME, { newName });
  };

  const sendNewWorkType = ({ id, name, backgroundColor }) => {
    saveAndSendAction(EditorCommands.NEW_WORKTYPE, {
      id,
      name,
      backgroundColor,
    });
  };

  const addNewPermit = (workTypeId, userUuid) => {
    saveAndSendAction(EditorCommands.PERMIT_ADD, { workTypeId, userUuid });
  };

  const removePermit = (workTypeId, userUuid) => {
    saveAndSendAction(EditorCommands.PERMIT_REMOVE, { workTypeId, userUuid });
  };

  const setProjectUserWorkTypeVisibility = (userId, status) => {
    saveAndSendAction(EditorCommands.PERMIT_USER_VISIBILITY, { userId, status: status ? 1 : 0 });
  };

  const setPermitStatus = (workTypeId, status) => {
    saveAndSendAction(EditorCommands.PERMIT_STATUS, { workTypeId, status });
  };

  const addPdfCover = (cover) => {
    sendAction(EditorCommands.COVER_ADD, cover);
  };

  const removeCover = () => {
    sendAction(EditorCommands.COVER_REMOVE);
  };

  const updateCoverPages = (docId, usedPages, tablePos) => {
    saveAndSendAction(EditorCommands.COVER_PAGES, { docId, usedPages, tablePos });
  };

  const updateCoverScale = (docId, scaleObj) => {
    saveAndSendAction(EditorCommands.COVER_SCALE, { docId, scaleObj });
  };

  const requestRemoteSave = () => {
    sendAction(EditorCommands.REQUEST_SAVE);
  };
  
  
  const requestCommentAdd = (userId, place, body, mentions, internal, parentId) => {
    sendAction(EditorCommands.COMMENT_ADD, {
      userId,
      version: editorStore.currentVersionKey,
      place,
      body,
      mentions,
      internal,
      parentId,
    });
  };
  
  const requestCommentEdit = (id, userId, place, body, mentions, parentId) => {
    sendAction(EditorCommands.COMMENT_EDIT, {
      id,
      userId,
      version: editorStore.currentVersionKey,
      place,
      body,
      mentions,
      parentId,
    });
  };
  
  const requestCommentRemove = (commentId, userId, parent) => {
    sendAction(EditorCommands.COMMENT_REMOVE, {
      commentId,
      userId,
      version: editorStore.currentVersionKey,
      parent
    });
  };
  
  const requestCommentPathRemove = (place) => {
    sendAction(EditorCommands.COMMENT_REMOVE_PATH, {
      version: editorStore.currentVersionKey,
      place
    });
  };
  
  const requestCommentMove = (oldPlace, place) => {
    sendAction(EditorCommands.COMMENT_MOVE, {
      version: editorStore.currentVersionKey,
      place,
      oldPlace
    });
  };
  
  const requestShareSettingsUpdate = (settings) => {
    sendAction(EditorCommands.SHARE_SETTINGS, {
      settings: JSON.stringify(settings)
    });
  };

  const value = {
    users,
    connectionOnline,
    hasConnectionError,
    init,
    closeConnection,

    sendMoveData,
    requestStructureChange,
    requestStructurePatch,
    requestStructureVersionChange,
    requestNewVersion,
    requestVersionRename,
    requestVersionRemoval,
    requestVersionReorder,
    requestProjectRename,
    sendNewWorkType,
    addNewPermit,
    removePermit,
    setProjectUserWorkTypeVisibility,
    setPermitStatus,
    addPdfCover,
    removeCover,
    updateCoverPages,
    updateCoverScale,
    requestRemoteSave,
    requestCommentAdd,
    requestCommentEdit,
    requestCommentRemove,
    requestCommentPathRemove,
    requestCommentMove,
    requestShareSettingsUpdate,
  };

  return (
    <EditorSocketContext.Provider value={value}>
      {children}
    </EditorSocketContext.Provider>
  );
};

EditorSocketProvider.propTypes = {
  projectUuid: string.isRequired,
  children: node.isRequired,
  commentHash: string,
  isProposal: bool,
};
